import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Search from '../../imgs/search.png';
import UpArrow from '../../imgs/uparrow.png';
import DownArrow from '../../imgs/downarrow.png';
import auth from '../../Services/auth';
import { WaitBox } from '../WaitBox';
import { ImageViewMemo } from '../ImageView';
import { Container } from './styles';
import close from '../../imgs/close.png';
import { api } from '../../Services/api';
import { Download } from '../Download';
import { toast } from 'react-toastify';

type ResultType = {
	detectPartnerID:		number;
	detectPlate:			string;
	detectDateTime:			string;
	detectNumserie:			string;
	detectPath:				string;
	detectAlarme:			number;
	detectAlarmeTexto:		string;
	detectAlarmeCadDataHora:string;
	detectAlarmeCreatedBy:	string;
	detectFaixa:			number;
	detectCidade:			string;
	detectEndereco:			string;
};

interface SearchPlateProps {
	isOpen: boolean;
	onError: (res: number) => void;
	onClose: () => void;
}

export const SearchPlate = (props: SearchPlateProps) => {
	const [ plate, setPlate ] 			= useState('');
	const [ isRunning, setIsRunning ] 	= useState(false);
	const [ plateList, setPlateList ] 	= useState<ResultType[]>([]);
	const [ imageData, setImageData ] 	= useState<string|null>(null);
	const [ currentID, setCurrentID ] 	= useState(-1);
	const [ isOpen, setIsOpen ] 		= useState(false);
	const navigate 						= useNavigate();

	const handlePlateChange = (text: string) => {
		if(text.length === 3) {
			text += '-';
		}

		setPlate(text);
	}

	const handleExit = () => {
		auth.logout(() => {
			navigate('/login');
		});
	}

	const handleSearchClick = () => {
		setImageData(null);
		setCurrentID(-1);

		if(plate.length > 8 || plate.length < 3) {
			toast.warn('Placa Inválida');

			return;
		}

		//FBF-1M12 (1619973) - 06//08/2022 22:38:42
		setIsRunning(true);
		api.get('/api/getPlateList', {
			params: {
				plate: plate.toUpperCase(),
			}
		})
		.then((res: any) => res.data)
		.then((res: any) =>{
			setIsRunning(false);

			if(res.code === 999) {
				toast.error(res.message);

				navigate('/login');
			} else {
				setPlateList(res.list);
			}
		})
		.catch((err: any) => {
			setIsRunning(false);
			toast.error(err);
		})
	}

	const showImage = (imageID: number, index: number) => {
		setIsRunning(true);
		setImageData(null);
		setCurrentID(index);

		api.get('/api/getImage', {
			params: {
				partnerID: imageID,
			}
		})
		.then((res: any) => res.data)
		.then((res: any) =>{
			setIsRunning(false);
			if(res.code === 999) {
				toast.error(res.message);

				navigate('/login');
			} else {
				if(res.code === 0) {
					setImageData(res.image);
				}
			}
		})
		.catch((err: any) => {
			setIsRunning(false);
			toast.error(err);
		});
	}

	const previousID = () => {
		if(currentID > 0) {
			showImage(plateList[currentID - 1].detectPartnerID, currentID - 1);
		}
	}

	const nextID = () => {
		if(currentID !== -1 && currentID < plateList.length - 1) {
			showImage(plateList[currentID + 1].detectPartnerID, currentID + 1);
		}
	}

	return (
		<Container isOpen={props.isOpen}>
			<div className="header-div">
				<label>Pesquisa de Placa</label>
				<button onClick={() => props.onClose()} title="Fecha a janela"><img src={close} alt="Fecha a janela" /></button>
			</div>
			<div>
				<input placeholder="Placa" maxLength={ 8 } style={ styles.input } type="text" value={ plate } onChange={ (e) => handlePlateChange(e.target.value) }/>
				<button style={ styles.button } onClick={() => handleSearchClick() }><img width={ 20 } height={ 20 } src={ Search } alt=""/></button>
			</div>
			<div style={ styles.horizontalContainer }>
				<div style={ styles.divResultTable }>
					<table style={ styles.table }>
						<thead style={ styles.resultHead }>
							<tr>
								<th style={ styles.resultHead }>ID</th>
								<th style={ styles.resultHead }>Placa</th>
								<th style={ styles.resultHead }>Data Hora</th>
								<th style={ styles.resultHead }>Máquina</th>
								<th style={ styles.resultHead }>Faixa</th>
								<th style={ styles.resultHead }>Cidade</th>
								<th style={ styles.resultHead }>Endereço</th>
							</tr>
						</thead>
						<tbody>
							{
								plateList.map((row, index) => {
									return(
										<tr key={row.detectPartnerID} style={ (currentID===index)?styles.trHighlight:styles.empty }>
											<td style={ styles.resultCell }><div style={ styles.divIDLink } onClick={() => showImage(row.detectPartnerID, index) }>{row.detectPartnerID}</div></td>
											<td style={ styles.resultCellFixed }>{row.detectPlate}</td>
											<td style={ styles.resultCellFixed }>{row.detectDateTime}</td>
											<td style={ styles.resultCellFixed }>{row.detectNumserie}</td>
											<td style={ styles.resultCellCenter }>{row.detectFaixa}</td>
											<td style={ styles.resultCell }>{row.detectCidade}</td>
											<td style={ styles.resultCell }>{row.detectEndereco}</td>
										</tr>
									)
								})
							}
						</tbody>
					</table>
					<div style={ styles.divResultBottom }>
						<div>{plateList.length} item(ns)</div>
						<div style={ styles.divUpDown }>
							<button style={ styles.actionButton } onClick={() => previousID()}><img width={ 16 } src={ UpArrow } alt=""/></button>
							<button style={ styles.actionButton } onClick={() => nextID()}><img width={ 16 } src={ DownArrow } alt=""/></button>
						</div>
					</div>
				</div>
				<div style={ styles.divImage }>
					<ImageViewMemo style={ styles.image } data={ imageData } />
					<div style={ styles.divImageBottom }>
						<button onClick={() => setIsOpen(true) } style={ styles.downloadButton }>Download</button>
					</div>
				</div>
			</div>
			<Download isOpen={isOpen} onClose={() => setIsOpen(false) } data={imageData} />
			<WaitBox show={ isRunning } />
		</Container>
	)
}

const styles = {
	empty: {},
	horizontalContainer: {
		display: 'flex',
		flexDirection: 'row' as 'row',
		marginTop: '10px',
		width: '100%',
	},
	input: {
		border: 0,
		borderRadius: '10px',
		padding: '8px',
		width: '300px',
		marginTop: '10px',
		backgroundColor: 'black',
		color: 'white',
		fontSize: 18,
		textTransform: 'uppercase' as 'uppercase',
	},
	button: {
		border: 0,
		borderRadius: '10px',
		padding: '8px',
		width: '90px',
		height: '43px',
		fontSize: '18px',
		fontWeight: 'bold',
		cursor: 'pointer',
		color: 'blue',
		marginLeft: '10px',
	},
	downloadButton: {
		border: 0,
		borderRadius: '10px',
		padding: '8px',
		fontSize: '18px',
		fontWeight: 'bold',
		cursor: 'pointer',
		color: 'blue',
		marginLeft: '10px',
	},
	divResultTable: {
		width: '810px',
		height: '480px',
		borderRadius: '5px',
	},
	table: {
		border: '1px solid gray',
		borderRadius: '5px',
		backgroundColor: '#404040',
		borderCollapse: 'collapse' as 'collapse',
		display: 'block',
		overflow: 'auto',
		height: '100%',
	},
	trHighlight: {
		backgroundColor: 'red',
	},
	resultHead: {
		paddingLeft: '3px',
		paddingRight: '3px',
		border: '1px solid gray',
		borderCollapse: 'collapse' as 'collapse',
	},
	resultCell: {
		border: '1px solid gray',
		borderCollapse: 'collapse' as 'collapse',
		padding: '2px 5px 2px 5px',
		whiteSpace: 'nowrap' as 'nowrap',
	},
	resultCellCenter: {
		border: '1px solid gray',
		borderCollapse: 'collapse' as 'collapse',
		padding: '2px 5px 2px 5px',
		whiteSpace: 'nowrap' as 'nowrap',
		textAlign: 'center' as 'center',
	},
	resultCellFixed: {
		border: '1px solid gray',
		borderCollapse: 'collapse' as 'collapse',
		padding: '2px 5px 2px 5px',
		whiteSpace: 'nowrap' as 'nowrap',
		fontFamily: 'Courier New',
		fontWeight: 'bold',
	},
	divResultBottom: {
		display: 'flex',
		flexDirection: 'row' as 'row',
		justifyContent: 'space-between' as 'space-between',
		marginTop: '10px',
		padding: '5px 5px 5px 5px',
		backgroundColor: '#404040',
		boxShadow: '0px 0px 15px 1px #0000ff',
		borderRadius: '5px',
	},
	divImage: {
		marginLeft: '10px',

		borderRadius: '5px',
	},
	divIDLink: {
		fontWeight: 'bold',
		fontFamily: 'Courier New',
		textDecoration: 'underline',
		cursor: 'pointer' as 'pointer',
	},
	image: {
		width: '704px',
		height: '480px',
	},
	divImageBottom: {
		marginTop: '10px',
		display: 'flex',
		justifyContent: 'right',
	},
	divUpDown: {

	},
	actionButton: {
		marginLeft: '10px',
	},
}