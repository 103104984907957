import { ModalNewUserCityContainer } from './stylesModal';
import searchImg from '../../imgs/search.png'
import runningImg from '../../imgs/download.gif'
import { useState } from 'react';
import { api } from '../../Services/api';

interface ModalNewUserCityProps {
	isOpen: boolean;
	onItemSelected: (cidadeCodigo: number) => void;
	onClose: () => void;
}

interface Cities {
	cidadeCodigo:	number;
	cidadeNome: 	string;
	cidadeEstado: 	string;
}

export const ModalNewUserCity = (props: ModalNewUserCityProps) => {
	const [ cityName, setCityName ] 		= useState('');
	const [ isRunning, setIsRunning ] 		= useState(false);
	const [ cityList, setCityList ] 		= useState<Cities[]>([]);

	const handleSearchCidade = () => {

		if(cityName === '') {
			alert('Informe o nome ou parte do nome da cidade a procurar');

			return;
		}

		setIsRunning(true);

		api.get('/api/getCityByName', {
			params: {
				cityName: cityName
			}
		})
		.then(res => res.data)
		.then(res => {
			if(res.code !== 0) {
				alert(res.message);
			} else {
				setCityList(res.list);
			}
		})
		.catch(err => {
			alert(err);
		})
		.finally(() => {
			setIsRunning(false);
		});
	}

	return (
		<ModalNewUserCityContainer
			isOpen={props.isOpen}
		>
			<main>
				<div>
					<input type="text" placeholder="Nome da cidade" value={cityName} onChange={(e) => setCityName(e.target.value)}/>
					{
						isRunning &&
							<img className="downloading" width={24} height={24} src={ runningImg } alt="Processando" />
					}
					<img width={24} height={24} src={searchImg} title="Pesquisa Cidade" alt="Pesquisa Cidade" onClick={() => handleSearchCidade()}/>
				</div>
				<table>
					<thead>
						<tr>
							<th>Código</th>
							<th>Nome</th>
							<th>Estado</th>
						</tr>
					</thead>
					<tbody>
						{
							cityList.map((item, index) => {
								return (
									<tr key={index}>
										<td className="link" onClick={() => props.onItemSelected(item.cidadeCodigo)}>{item.cidadeCodigo}</td>
										<td>{item.cidadeNome}</td>
										<td>{item.cidadeEstado}</td>
									</tr>
								)
							})
						}
					</tbody>
					<tfoot>
						<tr>
							<th colSpan={3}>
								<button onClick={() => props.onClose()}>Fechar</button>
							</th>
						</tr>
					</tfoot>
				</table>
				{
					cityList.length >= 20 &&<label>Resultado limitado (Refine a pesquisa)</label>
				}
			</main>
		</ModalNewUserCityContainer>
	)
}