import styled from "styled-components";
import Modal from 'styled-react-modal'

export const Container = Modal.styled`
	max-height: 85%;
	max-width: 85%;

	position: relative;
  	display: flex;
  	align-items: flex-start;
	background-color: #505050;
	box-shadow: 0px 0px 15px 1px #0000ff;
	border-radius: 15px;
	padding: 10px 10px 10px 10px;
	display: flex;
	flex-direction: column;
	margin: 10px 10px 10px 30px;

	.header-div {
		margin-top: 1px;
		margin-bottom: 15px;
		display: flex;
		width: 100%;
		justify-content: space-between;

		label {
			font-size: 36px;
		}

		button {
			border: 0;
			border-radius: 10px;
			width: 30px;
			height: 30px;
			cursor: pointer;

			img {
				width: 18px;
				height: 18px;
			}
		}
	}
`;