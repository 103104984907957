import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { Login } from './components/Login';
import { Dashboard } from './components/Dashboard';
import auth from './Services/auth';
import { ModalProvider } from 'styled-react-modal'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
	return (
		<>
			<BrowserRouter>
				<ModalProvider>
					<Routes>
						<Route path="/" element={<Login />} />
						<Route element={<RequireAuth />}>
							<Route path="/dashboard" element={<Dashboard />} />
						</Route>
						<Route path="/login" element={<Login />} />
						<Route path="*" element={<Dashboard />} />
					</Routes>
				</ModalProvider>
				<ToastContainer autoClose={3000} />
			</BrowserRouter>
		</>
	)
}

function RequireAuth() {
	// state={{ from: location }}
	if (!auth.isAuthenticated()) {
		return <Navigate to="/login"  />;
	}

	return <Dashboard />;
  }

export default App
