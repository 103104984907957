import { memo, useEffect, useState } from "react";
import { Container,
		MainWindow,
		SmallWindow,
		SmallWindows,
		Text,
		Title,
		Since,
	} from "./styles";

interface ImageContainerProps {
	listenMaquina: 		string;
	listenMaquinaImg: 	string;
	listenMaquinaComp:	string;
	which:				number;
	onDrop:				(e: any, which: number) => any;
}

const resolveDisplay = (item: string) => {
	const str = item.toString();
	if(str.startsWith('CITY:')) {
		const tokens = str.split(':');

		return tokens[2];
	}

	return str;
}

interface Texts {
	date: string;
	time: string;
	plate: string;
}

const emptyText = () => {
	return {
		date: ' ',
		time: ' ',
		plate: ' ',
	}
}

const ImageContainer = (props: ImageContainerProps) => {
	const [ img1Mem, setImg1Mem ] 		= useState('');
	const [ mainImage, setMainImage ] 	= useState('');
	const [ img1, setImg1 ] 			= useState('');
	const [ img2, setImg2 ] 			= useState('');
	const [ img3, setImg3 ] 			= useState('');
	const [ img4, setImg4 ] 			= useState('');
	const [ textMem, setTextMem ]		= useState<Texts>(emptyText());
	const [ text1, setText1 ] 			= useState<Texts>(emptyText());
	const [ text2, setText2 ] 			= useState<Texts>({} as Texts);
	const [ text3, setText3 ] 			= useState<Texts>({} as Texts);
	const [ text4, setText4 ] 			= useState<Texts>({} as Texts);
	const [ firstDate, setFirstDate ]	= useState('');
	const [ total, setTotal ]			= useState(0);
	const [ title, setTitle ]			= useState('');

	useEffect(() => {
		rotateImage(props.listenMaquinaImg);
		rotateTexts(props.listenMaquinaComp);
		setTotal(total + 1);
	}, [props.listenMaquinaImg]);

	useEffect(() => {
		setTitle(`${resolveDisplay(props.listenMaquina)} - ${props.listenMaquinaComp}`);
	}, [ props.listenMaquinaComp, props.listenMaquina])

	const handleDrop = (e: any) => {
		setImg1Mem('');
		setMainImage('');
		setImg1('');
		setImg2('');
		setImg3('');
		setImg4('');
		setTextMem(emptyText());
		setText1(emptyText());
		setText2(emptyText());
		setText3(emptyText());
		setText4(emptyText());
		setFirstDate('');
		setTotal(0);
		setTitle('');

		props.onDrop(e, props.which);
	}

	const rotateImage = (img: string) => {
		if(img3 !== '') {
			setImg4(img3);
		}

		if(img2 !== '') {
			setImg3(img2);
		}

		if(img1 !== '') {
			setImg2(img1);
		}

		if(img1Mem !== ''){
			setImg1(img1Mem);
		}

		setImg1Mem(img);
		setMainImage(img);
	}

	const rotateTexts = (comp: string) => {
		const tokens 	= comp.split(' - ');
		const dateTime	= tokens[1];
		const plate		= tokens[2];

		if(dateTime) {
			const tDateTime	= dateTime.split(' ');
			const date		= tDateTime[0];
			const time		= tDateTime[1];

			if(firstDate === '') {
				setFirstDate(dateTime);
			}

			setText4(text3);
			setText3(text2);
			setText2(text1);
			setText1(textMem);

			setTextMem({
				date,
				time,
				plate,
			});
		}
	}

	return (
		<Container>
			<MainWindow
				backgroundImage={mainImage}
				onDrop={(e: any) => handleDrop(e) }
				onDragOver={(e: any) => e.preventDefault()}
			>
				<Title>{title}</Title>
				<Since>
					{
						firstDate?`Identificado ${total} placa(s) desde ${firstDate}`:''
					}
				</Since>
			</MainWindow>

			<SmallWindows>
				<SmallWindow backgroundImage={img1} />
				<Text>{text1.date}&nbsp;</Text>
				<Text>{text1.time}&nbsp;</Text>
				<Text>{text1.plate}&nbsp;</Text>
				<SmallWindow backgroundImage={img2} />
				<Text>{text2.date}&nbsp;</Text>
				<Text>{text2.time}&nbsp;</Text>
				<Text>{text2.plate}&nbsp;</Text>
				<SmallWindow backgroundImage={img3} />
				<Text>{text3.date}&nbsp;</Text>
				<Text>{text3.time}&nbsp;</Text>
				<Text>{text3.plate}&nbsp;</Text>
				<SmallWindow backgroundImage={img4} />
				<Text>{text4.date}&nbsp;</Text>
				<Text>{text4.time}&nbsp;</Text>
				<Text>{text4.plate}&nbsp;</Text>
			</SmallWindows>
		</Container>
	);
}

export const ImageContainerMemo = memo(ImageContainer);