import { useState, useEffect } from 'react';
import RightArrow from '../../imgs/arrow.png';
import Minus from '../../imgs/minus.png';
import Burger from '../../imgs/burger.png';
import { api } from '../../Services/api';
import { Container } from './styles';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface MenuProps {
	show: 			boolean;
	onShowClick: 	() => void;
	onMenuClick: 	(item: string) => void;
}

interface IMenuList {
	resourceID: 			number;
	resourceName: 			string;
	resourceDescription:	string;
	resourceAction: 		string;
	resourceDepth: 			number;
}

export const Menu = (props: MenuProps) => {
	const [ menuList, setMenuList]	= useState<IMenuList[]|null>(null);
	const navigate 					= useNavigate();

	useEffect(() => {
		api.get('/api/getMenu')
		.then(res => res.data)
		.then(res => {
			if(res.code === 999) {
				navigate('/login');
			}
			else if(res.code === 0) {
				setMenuList(res.list);
			} else {
				setMenuList(null);
			}
		})
		.catch(err => {
			toast.error(err);
			setMenuList(null);
		});
	}, [navigate]);

	const handleItemClick = (item: string) => {
		if(item !== '') {
			props.onMenuClick(item);
		}
	}

	return (
		<Container>
		{ props.show &&
			<div className="menu">
				<div className="title">Menu</div>
				{
					menuList?.map(item => {
						const a = new Array(item.resourceDepth===1?0:item.resourceDepth*2).fill(' ');

						return(
							<div
								key={item.resourceID}
								className="menuItem"
								onClick={() => handleItemClick(item.resourceAction)}
								title={item.resourceDescription}
							>
								{
									a.map((i, index) => <span key={index}>&nbsp;</span>)
								}
								<img width={16} src={item.resourceDepth===1?RightArrow:Minus} alt="Seta" />{item.resourceName}
							</div>
						)
					})
				}
			</div>
		}
			<div className="header">
				<div className="button" onClick={ props.onShowClick }><img width={48} src={ Burger } alt="Burger" /></div>
			</div>
		</Container>
	);
}