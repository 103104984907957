import Cookies from 'js-cookie';
import { Config } from '../config';
import { api } from './api';

class Auth {
	private user: string = '';

    logout = (cb: any) => {
        Cookies.set('common-token', 	'', { expires: 1 } );

        api.get(Config.baseAddr + '/api/logout')
		.then(res => res.data)
        .then(res => {
            cb();
        })
		.catch(err => {
			console.log(err)
			cb();
		});
    }

	setUser = (user: string) => {
		this.user = user;
	}

	getUser = () => {
		return this.user;
	}

    getToken = (): string => {
        const token = Cookies.get('common-token');

		if(token === undefined) {
			return '';
		}
		
		return token;
    }

	isAuthenticated = () => {
		const token = Cookies.get('common-token');
		return token === undefined || token !== '';
	}
}

export default new Auth();