import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DetectaImg from '../../imgs/detecta.png';
import Download from '../../imgs/download.gif'
import { Container } from './styles';
import { ChangePasswordModal } from '../ChangePassword';
import { Config } from '../../config';
import { api } from '../../Services/api';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import auth from '../../Services/auth';

export const Login = () => {
	const [ user, setUser ] 							= useState('');
	const [ password, setPassword ] 					= useState('');
	const [ loading, setLoading ]						= useState(false);
	const [ showChangePassword, setShowChangePassword]	= useState(false);
	const navigate 										= useNavigate();

	const enableControls = (enable: boolean) => {
		setLoading(!enable);
	}

	const handleSubmit = () => {
		auth.setUser('');

		if(loading) {
			return;
		}

		if(user === '' || password === '') {
			toast.warn('Usuário ou senha inválidos');

			return;
		}

		if(password === '123mudar') {
			toast.error(`A senha deve ser alterada. Não será possível utilizar a senha inicial`);

			return;
		}

		enableControls(false);
		api.post(Config.baseAddr + '/api/login', {
			user,
			password,
		})
		.then(res => res.data)
		.then(res => {
			if(res.code !== 0) {
				toast.error(res.message);
			} else {
				auth.setUser(res.userName);
				Cookies.set('common-token', 	res.id, { expires: 1 } );

				navigate('/dashboard');
			}
		})
		.catch(err => {
			Cookies.set('common-token', 	'', { expires: 1 } );
			toast.error(err);
		})
		.finally(() => {
			enableControls(true);
		})
	}

	const handleShowChangePassword = () => {
		setShowChangePassword(true);
	}

	const handleCloseChangePassword = () => {
		setShowChangePassword(false);
	}

	return (
		<Container>
			<div className="input-box">
				<div className="title-box">
					<img width={80} src={DetectaImg} alt="" />
					<div className="title">Sistema Detecta</div>
				</div>
				<input placeholder="Usuário" type="text" value={user} onChange={e => setUser(e.target.value)}/>
				<input placeholder="Senha" type="password" value={password} onChange={e => setPassword(e.target.value)}/>
				<div className="button-box">
					{
						loading &&
							<img className="downloading" width={24} height={24} src={ Download } alt="Processando" />
					}
					<button onClick={() => handleShowChangePassword()}>Alterar Senha</button>
					<button onClick={() => handleSubmit()}>Entrar</button>
				</div>
			</div>
			<ChangePasswordModal
				isOpen={showChangePassword}
				onRequestClose={handleCloseChangePassword}
			></ChangePasswordModal>
		</Container>
	);
}