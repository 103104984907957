import { useState } from 'react';
import { Container, ModalContainer, CloseButton } from './styles';
import close from '../../imgs/close.png';
import Download from '../../imgs/download.gif'
import { api } from '../../Services/api';
import { toast } from 'react-toastify';

interface ChangePasswordModalProps {
	isOpen: boolean;
	onRequestClose: () => void;
}

export const ChangePasswordModal = (props: ChangePasswordModalProps) => {
	const [ login, setLogin ] 						= useState('');
	const [ currentPassword, setCurrentPassword ] 	= useState('');
	const [ newPassword, setNewPassword ] 			= useState('');
	const [ repeatPassword, setRepeatPassword ] 	= useState('');
	const [ loading, setLoading ]					= useState(false);

	const handleOK = () => {
		if(loading) {
			return;
		}

		if(login === '' || login.length > 50 || login.length < 5) {
			toast.warn('Usuário inválido');

			return;
		}

		if(currentPassword==='' || newPassword==='' || repeatPassword==='') {
			toast.warn('Todos os campos devem ser preenchidos');

			return;
		}

		if(currentPassword === newPassword) {
			toast.warn('A nova senha tem que ser diferente da senha atual');

			return;
		}

		if(newPassword === '123mudar') {
			toast.warn('A nova senha não pode ser a padrão');
			return;
		}

		if(newPassword.length < 8 || newPassword.length > 20) {
			toast.warn('A senha tem que ter pelo menos 8 caracteres e no máximo 20');

			return;
		}

		if(newPassword !== repeatPassword) {
			toast.warn('As senhas novas não conferem');

			return;
		}

		setLoading(true);
		api.post('/api/changePassword' , {
			user: login,
			currentPassword: currentPassword,
			newPassword: newPassword,
		})
		.then(res => res.data)
		.then(res => {
			if(res.code !== 0) {
				toast.error(res.message);
			} else {
				toast.info('Senha alterada com sucesso');

				props.onRequestClose();
			}
		})
		.catch(err => {
			toast.error(err);
		})
		.finally(() => {
			setLoading(false);
		});
	}

	return (
		<ModalContainer
			isOpen={props.isOpen}
		>
			<Container>
				<CloseButton
					type="button"
					onClick={ props.onRequestClose }
				>
					<img src={ close } alt="Fechar modal" />
				</CloseButton>
				<label>Troca se Senha</label>
				<table>
					<tbody>
						<tr><td><input type="text" placeholder="Login" value={login} onChange={(e) => setLogin(e.target.value)}/></td></tr>
						<tr><td><input type="password" placeholder="Senha Atual" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)}/></td></tr>
						<tr><td><input type="password" placeholder="Senha Nova" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/></td></tr>
						<tr><td><input type="password" placeholder="Repetir a Senha Nova" value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)}/></td></tr>
						<tr>
							<td className='td-button'>
							{
								loading &&
									<img className="downloading" width={24} height={24} src={ Download } alt="Processando" />
							}
								<button title="Cancela a alteração" onClick={ props.onRequestClose }>Cancelar</button>
								<button title="Grava a alteração" onClick={handleOK}>Ok</button>
							</td>
						</tr>
					</tbody>
				</table>
			</Container>
		</ModalContainer>
	);
}