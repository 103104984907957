import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { api } from '../../Services/api';
import { WaitBox } from '../WaitBox';
import { Container } from './styles';
import close from '../../imgs/close.png';
import { toast } from 'react-toastify';

interface CidadeDetail  {
	cidadeCodigo: number;
	cidadeNome: string;
	cidadeEstado: string;
}

interface MaquinaDetail {
	maquinaModified: 	boolean;
	maquinaSerial: 		string;
	maquinaFaixa:		string;
	maquinaCodCidade: 	number;
	maquinaCidade:		string;
	maquinaAtiva: 		number;
	maquinaTipo: 		string;
	maquinaLatitude: 	string;
	maquinaLongitude: 	string;
	maquinaEndereco: 	string;
	maquinaPM:			string;
	maquinaMJ:			string;
	maquinaPRF:			string;
}

interface CadastroMaquinaProps {
	isOpen: boolean;
	onError: (res: number) => void;
	onClose: () => void;
}

export const CadastroMaquina = (props: CadastroMaquinaProps) => {
	const [ maquinaList, setMaquinaList]				= useState<MaquinaDetail[]>([]);
	const [ maquinaListOriginal, setMaquinaListOriginal]= useState<MaquinaDetail[]>([]);
	const [ cidadeList, setCidadeList] 					= useState<CidadeDetail[]>([]);
	const [ isRunning, setIsRunning ] 					= useState(false);
	const navigate 										= useNavigate();

	useEffect(() => {
		const init = async() => {
			let res = 0;

			res = await fillCidadeList();

			if(res === 0) {
				res = await fillList();
			}

			if(res !== 0) {
				props.onError(res);
			}
		};

		init();
	}, [props]);

	const fillCidadeList = async(): Promise<number> => {
		let retValue = new Promise<number>((resolve, reject) => {
			setIsRunning(true);

			api.get('/api/getCidadeList')
			.then(res => res.data)
			.then(res =>{
				setIsRunning(false);

				if(res.code !== 0) {
					toast.error(res.message);

					resolve(res.code);
				} else {
					setCidadeList(res.list);
					resolve(0);
				}
			})
			.catch(err => {
				setIsRunning(false);
				toast.error(err);
			});
		});

		return retValue;
	}

	const fillList = async (): Promise<number> => {
		let retValue = new Promise<number>((resolve, reject) => {
			setIsRunning(true);

			api.get('/api/getMaquinaList')
			.then(res => res.data)
			.then(res =>{
				setIsRunning(false);

				if(res.code !== 0) {
					toast.error(res.message);

					resolve(res.code);
				} else {
					setMaquinaList(res.list);
					setMaquinaListOriginal(JSON.parse(JSON.stringify(res.list)));

					resolve(0);
				}
			})
			.catch(err => {
				setIsRunning(false);
				toast.error(err);
			});
		});

		return retValue;
	}

	const setMaquinaData = (type: string, index: number, newData: string) => {
		let m = maquinaList[index];

		switch(type) {
			case 'serial':
				if(maquinaListOriginal[index].maquinaSerial === '') {
					m.maquinaSerial = newData.toUpperCase();
				}
				break;
			case 'faixa':
				//if(maquinaListOriginal[index].maquinaFaixa === '') {
					m.maquinaFaixa = newData;
				//}
				break;
			case 'ativa':
				m.maquinaAtiva = (m.maquinaAtiva==0)?1:0;
				break;
			case 'cidade':
				m.maquinaCodCidade = +newData;
				break;
			case 'tipo':
				m.maquinaTipo = newData;
				break;
			case 'latitude':
				m.maquinaLatitude = newData;
				break;
			case 'longitude':
				m.maquinaLongitude = newData;
				break;
			case 'endereco':
				m.maquinaEndereco = newData;
				break;
			case 'pm':
				m.maquinaPM = newData;
				break;
			case 'mj':
				m.maquinaMJ = newData;
				break;
			case 'prf':
				m.maquinaPRF = newData;
				break;
		}

		m.maquinaModified = isModified(m, index);

		const pre = maquinaList.slice(0, index);
		const pos = maquinaList.slice(index+1);

		setMaquinaList([...pre, m, ...pos]);
	}

	const isMaquinaListValid = (s: MaquinaDetail[]) => {
		if(s.length === 0) {
			toast.warn('Não existem registro para salvar...');

			return false;
		}

		for(let n=0; n<maquinaList.length; ++n) {
			console.log('n', n)
			for(let t=0; t<maquinaList.length; ++t) {
				if(n !== t) {
					console.log(maquinaList[n].maquinaSerial, maquinaList[t].maquinaSerial, maquinaList[n].maquinaFaixa, maquinaList[t].maquinaFaixa)
					if(maquinaList[n].maquinaSerial === maquinaList[t].maquinaSerial &&
						maquinaList[n].maquinaFaixa === maquinaList[t].maquinaFaixa) {
							toast.warn('Existem registros com Serial e Faixa repetidos');

							return false;
						}
				}
			}
		}

		return true;
	}

	const isModified = (m: MaquinaDetail, index: number): boolean => {
		const mo 		= 	maquinaListOriginal[index];

		return	mo.maquinaSerial 	!== m.maquinaSerial 	||
				mo.maquinaFaixa		!== m.maquinaFaixa		||
				mo.maquinaCodCidade	!== m.maquinaCodCidade	||
				mo.maquinaCidade 	!== m.maquinaCidade 	||
				mo.maquinaAtiva 	!== m.maquinaAtiva 		||
				mo.maquinaTipo 		!== m.maquinaTipo 		||
				mo.maquinaLatitude 	!== m.maquinaLatitude 	||
				mo.maquinaLongitude	!== m.maquinaLongitude	||
				mo.maquinaEndereco 	!== m.maquinaEndereco 	||
				mo.maquinaPM		!== m.maquinaPM			||
				mo.maquinaMJ		!== m.maquinaMJ			||
				mo.maquinaPRF		!== m.maquinaPRF;
	}

	const save = () => {

		const s = maquinaList.filter(item => (item.maquinaModified && item.maquinaFaixa !== '' && item.maquinaFaixa !== '') && item);

		if(!isMaquinaListValid(s)) {
			return;
		}

		setIsRunning(true);

		api.post('/api/postMaquinaList', { data: s })
		.then(res => res.data)
		.then(res =>{
			setIsRunning(false);

			if(res.code === 999) {
				toast.error(res.message);

				navigate('/login');
			} else if(res.code === 998) {
				toast.error(res.message);
			} else if(res.code !== 0) {
				toast.error(res.message);
			} else {
				fillList();
			}
		})
		.catch(err => {
			setIsRunning(false);
			toast.error(err);
		});
	}

	const getEmptyMaquinaDetail = (): MaquinaDetail => {
		const m = {} as MaquinaDetail;

		m.maquinaModified	= false;
		m.maquinaSerial		= '';
		m.maquinaFaixa		= '';
		m.maquinaCodCidade	= 12345;
		m.maquinaCidade		= 'London';
		m.maquinaAtiva		= 0;
		m.maquinaTipo		= 'EB-RFAL-4F';
		m.maquinaLatitude	= '';
		m.maquinaLongitude	= '';
		m.maquinaEndereco	= '';
		m.maquinaPM			= '';
		m.maquinaMJ			= '';
		m.maquinaPRF		= '';

		return m;
	}

	const newMaquina = () => {
		setMaquinaList([...maquinaList, getEmptyMaquinaDetail()]);
		setMaquinaListOriginal([...maquinaListOriginal, getEmptyMaquinaDetail()]);
	}

	return (
		<Container isOpen={props.isOpen}>
			<div className="header-div">
				<label>Cadastro de Máquinas</label>
				<button onClick={() => props.onClose()} title="Fecha a janela"><img src={close} alt="Fecha a janela" /></button>
			</div>
			<table>
				<thead>
					<tr>
						<th>Serial</th>
						<th>Faixa</th>
						<th>Ativa</th>
						<th>Cidade</th>
						<th>Tipo</th>
						<th>Latitude</th>
						<th>Longitude</th>
						<th>Parceiro PM</th>
						<th>Parceiro MJ</th>
						<th>Parceiro PRF</th>
						<th>Endereço</th>
					</tr>
				</thead>
				<tbody>
				{
					maquinaList.map((maquina, index) => {
						return (
							<tr className={ (maquina.maquinaModified)?'modified':'normal'} key={index}>
								<td><input type="text" value={maquina.maquinaSerial} maxLength={10} size={10} onChange={(e) => {setMaquinaData('serial', index, e.target.value)}}/></td>
								<td><input type="text" value={maquina.maquinaFaixa} maxLength={3} size={3} onChange={(e) => {setMaquinaData('faixa', index, e.target.value.replace(/\D/,''))}}/></td>
								<td><input type="checkbox" checked={(maquina.maquinaAtiva==1)} onChange={(e) => {setMaquinaData('ativa', index, '')}}/></td>
								<td>
									<select value={maquina.maquinaCodCidade} onChange={(e) => {setMaquinaData('cidade', index, e.target.value)}}>
										{
											cidadeList.map((cidade) => {
												return <option key={cidade.cidadeCodigo} value={cidade.cidadeCodigo}>{cidade.cidadeNome} ({cidade.cidadeCodigo})</option>
											})
										}
									</select>
								</td>
								<td>
									<select value={maquina.maquinaTipo} onChange={(e) => {setMaquinaData('tipo', index, e.target.value)}}>
										<option value="EB-RFAL-4F">Sisdetran EB-RFAL-4F</option>
										<option value="544">Sistema Relevante 544</option>
									</select>
								</td>
								<td><input type="text" value={maquina.maquinaLatitude} maxLength={15} size={15} onChange={(e) => {setMaquinaData('latitude', index, e.target.value)}}/></td>
								<td><input type="text" value={maquina.maquinaLongitude} maxLength={15} size={15} onChange={(e) => {setMaquinaData('longitude', index, e.target.value)}}/></td>
								<td>
									<input type="text" value={maquina.maquinaPM} maxLength={30} size={15} onChange={e => {setMaquinaData('pm', index, e.target.value)}} />
								</td>
								<td>
									<input type="text" value={maquina.maquinaMJ} maxLength={30} size={15} onChange={e => {setMaquinaData('mj', index, e.target.value)}} />
								</td>
								<td>
									<input type="text" value={maquina.maquinaPRF} maxLength={30} size={15} onChange={e => {setMaquinaData('prf', index, e.target.value)}} />
								</td>
								<td><input type="text" value={maquina.maquinaEndereco} maxLength={512} size={50} onChange={(e) => {setMaquinaData('endereco', index, e.target.value)}}/></td>
							</tr>
						)
					})
				}
				</tbody>
				<tfoot>
					<tr>
						<td colSpan={11}>
							<button onClick={newMaquina}>Nova</button>
							<button onClick={save}>Salvar</button>
						</td>
					</tr>
				</tfoot>
			</table>
			<WaitBox show={ isRunning } />
		</Container>
	);
}
