import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: auto;
	margin: 3px 0px 0px 10px;

	.header {
		display: flex;
		justify-content: space-between;

		.headerLeft {
			display: flex;
		
			label {
				margin-left: 20px;;
				font-size: 36px;
			}
		}
	}

	.td-bold {
		background: #404040;
		color: white;
		font-weight: bold;
	}

	.draggable {
		cursor: pointer;
	}

`;