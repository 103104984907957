import Running from '../../imgs/download.gif'
import { Container } from './styles';

interface Props {
	show: boolean;
}

export const WaitBox = (props: Props) => {
	return (
		(props.show) ?
			<Container>
				<div className="box">
					<div>
						<img width={48} height={48} src={ Running } alt="" />
					</div>
					<div className="process-text">Processando</div>
				</div>
			</Container>:null
	);
}
