import Modal from 'styled-react-modal'

export const ModalNewUserCityContainer = Modal.styled`
	position: relative;
  	display: flex;
  	align-items: flex-start;
  	justify-content: center;
  	background-color: #505050;
	box-shadow: 0px 0px 15px 1px #0000ff;
	border-radius: 15px;

	main {
		margin: 10px;

		div {
			display: flex;
			flex-direction: row;
			align-items: center;

			img {
				margin-left: 5px;
				margin-top: 5px;
				cursor: pointer;
			}
		}

		input {
			border: 0;
			border-radius: 10px;
			padding: 8px;
			width: 300px;
			margin-top: 10px;
			box-shadow: 0px 0px 15px 1px #0000ff;
			background: black;
			color: white;
			font-size: 18;
		}

		.link {
			cursor: pointer;
			text-decoration: underline;
			color: #000070;
		}

		table {
			border: 1px solid gray;
			border-radius: 5px;
			background: #404040;
			border-collapse: collapse;
			display: block;
			overflow: auto;
			width: 100%;
			height: 100%;
			padding: 10px;
			margin-top: 10px;

			thead {
				th {
					padding-left: 3px;
					padding-right: 3px;
					border: 1px solid gray;
					border-collapse: collapse;
				}
			}

			tbody {
				td {
					border: 1px solid gray;
					border-collapse: collapse;
					padding: 2px 5px 2px 5px;
					white-space: nowrap;
				}

				.modified {
					background: red;
				}
			}

			tfoot {
				th {
					padding: 0px 5px 5px 5px;
					background: #404040;
					border-radius: 5px;
					text-align: right;

					button {
						border: 0;
						border-radius: 10px;
						padding: 8px;
						width: 90px;
						font-size: 16px;
						font-weight: bold;
						box-shadow: 0px 0px 15px 1px #0000ff;
						cursor: pointer;
						color: blue;
						margin-left: 10px;
						margin-top: 10px;
					}
				}
			}
		}
	}
`;
