import { url } from "inspector";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: row;
`;

interface ImageContainerProps {
	backgroundImage: string;
}

export const MainWindow = styled.div.attrs<ImageContainerProps>(
	({ backgroundImage }) => ({
		style: {
			backgroundImage: `url(${backgroundImage})`,
		}
	})
)<ImageContainerProps>`
	position: relative;
	background-size: 100%;
	-webkit-background-size: 100%;
	background-repeat: no-repeat;
	box-shadow: 0px 0px 15px 1px #0000ff;
	width: 700px;
	height: 393px;
	border-radius: 5px;
	background-color: blue;

	border: 3px solid red;
	margin: 0px 0 15px 0px;
`;

export const Title = styled.span`
	font-size: 22px;
`;

export const Since = styled.div`
	display: flex;
	position: absolute;
	right: 4px;
	bottom: 2px;
`;

interface SmallWindowProps {
	backgroundImage: string;
}

export const SmallWindow = styled.div.attrs<SmallWindowProps>(
	({ backgroundImage }) => ({
			style: {
				backgroundImage: `url(${backgroundImage})`,
			}
		})
	)<SmallWindowProps>`

	background-size: 100%;
	-webkit-background-size: 100%;
	background-repeat: no-repeat;
	position: relative;
	background-size: 100%;
	-webkit-background-size: 100%;
	background-repeat: no-repeat;
	box-shadow: 0px 0px 15px 1px #0000ff;
	left: 0px;
	top: 0px;
	width: 71px;
	height: 50px;
	border-radius: 2px;
	background-color: blue;

	border: 3px solid red;
	margin: 0px 5px 0px 5px;
`;

export const SmallWindows = styled.div``;

export const Text = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 0px;
`;