import styled from "styled-components";

export const Container = styled.div`
	.main-div {
		display: flex;
		flex-direction: row;
		justify-content: left;

		.main-image-div {
			display: grid;
			grid-template-columns: 50% 50%;
		}

		.main-right {
			display: flex;
			flex-direction: column;
			margin-left: 5px;
			margin-top: 0px;

			.top-label {
				text-align: right;
				margin-bottom: 5px;
			}

			header {
				display: flex;
				justify-content: space-between;
				width: 100%;
				text-align: center;
				font-size: 16px;
				color: white;
				background: blue;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;

				button {
					border: 0;
					cursor: pointer;
					background: none;
				}
			}

			.maquina-div {
				box-shadow: 0px 0px 15px 1px #0000ff;
				width: 300px;
				height: 50%;
				background: black;
				border-radius: 5px;
				margin-bottom: 15px;

				table {
					display: flex;
					overflow: hidden;
					overflow-y: scroll;
					width: 100%;
					height: 92%;
					border-bottom-left-radius: 5px;
					border-bottom-right-radius: 5px;
				}

				td {
					white-space: nowrap;
				}
			}

			.passagens-div {
				box-shadow: 0px 0px 15px 1px #0000ff;
				width: 300px;
				height: 50%;
				background: black;
				border-radius: 5px;

				.passagens-lista {
					overflow: auto;
					width: 98%;
					height: 92%;
					margin: 5px 5px 5px 5px;
					font-family: 'monospace';
					font-size: 12px;

					ul {
						margin: 0;
						padding: 0;
					}

					li {
						white-space: nowrap;
						list-style-type: none;
						margin: 0;
						padding: 0;
					}
				}
			}
		}

		.walls {
			display: flex;
			grid-column: 1 / span 2;
		}
	}

`;

interface WallProps {
	show: boolean;
}

export const Wall = styled.div<WallProps>`
	align-items: flex-start;
	width: 90px;
	margin-left: 0px;
	margin-right: 10px;
	margin-top: 5px;
	border-radius: 5px;
	visibility: ${props => props.show?'show':'hidden'};

	.wall-main {
		display: grid;
		width: 0 auto;
		grid-template-columns: 50% 50%;
		gap: 2px;
		align-items: flex-start;
		box-shadow: 0px 0px 15px 1px #0000ff;
	}

	footer {
		padding-top: 2px;
		display: flex;
		font-family: sans-serif;
		grid-column: 1 / 2;
		justify-content: center;
	}
`;

export const WallContainer = styled.div`
	width: 40px;
	height: 40px;
	background: blue;
	border: 2px solid red;

	span {
		display: block;
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

export const SmallWindow = styled.div`
	position: relative;
	background-size: 100%;
	-webkit-background-size: 100%;
	background-repeat: no-repeat;
	box-shadow: 0px 0px 15px 1px #0000ff;
	left: 0px;
	top: 0px;
	width: 71px;
	height: 50px;
	border-radius: 2px;
	background-color: blue;

	border: 3px solid red;
	margin: 5px 5px 0px 5px;
`;

export const Text = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 0px;
`;