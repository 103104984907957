import axios from "axios";
import { Config } from '../config';
//import auth from './auth';

export const api = axios.create({
	baseURL: Config.baseAddr,
	//headers: {},
	withCredentials: true,
	timeout: 2*60*1000, // 2 minutes
});

/*api.interceptors.request.use((request) => {
	//console.log(response);

	if(request && request.headers) {
		request.headers.Authorization = auth.getToken();
	}

	return request;
})*/