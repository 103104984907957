import styled from "styled-components";

export const Container = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;

	.box {
		display: flex;
		width: 250px;
		height: 130px;
		border: 2px solid blue;
		border-radius: 10px;
		justify-content: center;
		align-items: center;
		background: gray;
		opacity: 0.9;
		flex-direction: column;
	}

	.process-text {
		font-size: 24;
		font-weight: bold;
		color: black;
	}
`;