import styled from "styled-components";
import Modal from 'styled-react-modal'

export const Container = Modal.styled`
	flex-direction: column;
	padding: 10px 10px 10px 10px;
	max-height: 85%;
	max-width: 95%;

	position: relative;
  	display: flex;
  	align-items: flex-start;
  	background-color: #505050;
	box-shadow: 0px 0px 15px 1px #0000ff;
	border-radius: 15px;

	.header-div {
		margin-top: 1px;
		margin-bottom: 15px;
		display: flex;
		width: 100%;
		justify-content: space-between;

		label {
			font-size: 36px;
		}

		button {
			border: 0;
			border-radius: 10px;
			width: 30px;
			height: 30px;
			cursor: pointer;

			img {
				width: 18px;
				height: 18px;
			}
		}
	}

	.main-div {
		display: flex;
		flex-direction: row;
		overflow-x: auto;
		overflow-y: auto;
		width: 100%;
	}

	table {
		border: 1px solid gray;
		border-radius: 5px;
		background: #404040;
		border-collapse: collapse;
		display: block;
		overflow: auto;
		width: 100%;
		height: 100%;
		padding: 10px;

		thead {
			th {
				padding-left: 3px;
				padding-right: 3px;
				border: 1px solid gray;
				border-collapse: collapse;
			}
		}

		tbody {
			td {
				border: 1px solid gray;
				border-collapse: collapse;
				padding: 2px 5px 2px 5px;
				white-space: nowrap;
			}

			.modified {
				background: red;
			}
		}

		tfoot {
			th {
				padding: 0px 5px 5px 5px;
				background: #404040;
				border-radius: 5px;
				text-align: right;

				button {
					border: 0;
					border-radius: 10px;
					padding: 8px;
					width: 90px;
					font-size: 16px;
					font-weight: bold;
					box-shadow: 0px 0px 15px 1px #0000ff;
					cursor: pointer;
					color: blue;
					margin-left: 10px;
					margin-top: 10px;
				}
			}
		}
	}
`;

export const ContainerList = styled.div`
	display: flex;
	height: 600px;

	.action-image {
		width: 24px;
		margin-top: 3px;
		cursor: pointer;
	}
`;

export const ContainerDetail = styled.div`
	display: flex;
	height: 600px;
	margin-left: 20px;
`;

export const ContainerCidade = styled.div`
	display: flex;
	height: 600px;
	margin-left: 20px;

	.remove-img {
		cursor: pointer;
	}
`;
