import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../../Services/api';
import { WaitBox } from '../WaitBox';
import changePassword from '../../imgs/changePasswod.png';
import remove from '../../imgs/remove.png';
import arrow from '../../imgs/arrow.png';
import close from '../../imgs/close.png';
import { ModalNewUserCity } from './ModalNewUserCity';
import { toast } from 'react-toastify';
import { Container,
			ContainerList,
			ContainerDetail,
			ContainerCidade } from './styles';

interface CadastroUsuarioProps {
	isOpen: boolean;
	onError: (res: number) => void;
	onClose: () => void;
}

interface UserDetail {
	userModified: 	boolean;
	userLogin: 		string;
	userName: 		string;
	userEnabled: 	number;
	userLastLogin: 	string;
	userCreation: 	string;
}

interface ResourceDetail {
	resourceID:				number;
	resourceName:			string;
	resourceDescription:	string;
	resourceParent:			number;
	resourceType:	 		number;
	resourceSequence:		number;
	resourceAction:	 		string;
	resourceDepth: 			number;
}

interface UserCidadeDetail {
	cidadeCodigo: 			number;
	cidadeNome: 			string;
	cidadeEstado: 			string;
}

interface UserPermissionDetail {
	resourceID: 			number;
}

export const CadastroUsuario = (props: CadastroUsuarioProps) => {
	const [ userList, setUserList ] 					= useState<UserDetail[]>([]);
	const [ userListOriginal, setUserListOriginal ]		= useState<UserDetail[]>([]);
	const [ resourceList, setResourceList ]				= useState<ResourceDetail[]>([]);
	const [ userCidadeList, setUserCidadeList ]			= useState<UserCidadeDetail[]>([]);
	const [ userPermissionList, setUserPermissionList ]	= useState<UserPermissionDetail[]>([]);
	const [ currentUser, setCurrentUser ]				= useState('');
	const [ isRunning, setIsRunning ] 					= useState(false);
	const [ newUserCityIsOpen, setNewUserCityIsOpen ] 	= useState(false);
	const navigate 										= useNavigate();

	useEffect(() => {
		const init = async() => {
			let res = 0;

			res = await fillUserList();

			if(res === 0) {
				res = await fillResourceList();
			}

			if(res !== 0) {
				props.onError(res);
			}
		};

		init();
	}, [props]);

	const fillUserList = async(): Promise<number> => {
		let retValue = new Promise<number>((resolve, reject) => {
			setIsRunning(true);

			api.get('/api/getUserList')
			.then(res => res.data)
			.then(res =>{
				setIsRunning(false);

				if(res.code !== 0) {
					toast.error(res.message);

					resolve(res.code);
				} else {
					setUserList(res.list);
					setUserListOriginal(JSON.parse(JSON.stringify(res.list)));
					resolve(0);
				}
			})
			.catch(err => {
				setIsRunning(false);
				toast.error(err);
			});
		});

		return retValue;
	}

	const fillResourceList = async(): Promise<number> => {
		let retValue = new Promise<number>((resolve, reject) => {
			setIsRunning(true);

			api.get('/api/getFullMenu')
			.then(res => res.data)
			.then(res =>{
				setIsRunning(false);

				if(res.code !== 0) {
					toast.error(res.message);

					resolve(res.code);
				} else {
					setResourceList(res.list);

					resolve(0);
				}
			})
			.catch(err => {
				setIsRunning(false);
				toast.error(err);
			});
		});

		return retValue;
	}

	const setUserData = (type: string, index: number, newData: string) => {
		let m = userList[index];

		switch(type) {
			case 'login':
				if(userListOriginal[index].userLogin === '') {
					m.userLogin = newData;
				}
				break;
			case 'name':
				m.userName = newData;
				break;
			case 'enabled':
				m.userEnabled = +m.userEnabled===1?0:1;
				break;
		}

		m.userModified = isModified(m, index);

		const pre = userList.slice(0, index);
		const pos = userList.slice(index+1);

		setUserList([...pre, m, ...pos]);
	}

	const isModified = (m: UserDetail, index: number): boolean => {
		const mo 		= 	userListOriginal[index];

		return 	mo.userLogin		!== m.userLogin			||
				mo.userName 		!== m.userName			||
				mo.userEnabled 		!== m.userEnabled;
	}

	const getEmptyUserDetail = (): UserDetail => {
		const m = {} as UserDetail;

		m.userModified		= false;
		m.userLogin			= '';
		m.userName			= '';
		m.userEnabled		= 0;

		return m;
	}

	const newUser = () => {
		setUserList([...userList, getEmptyUserDetail()]);
		setUserListOriginal([...userListOriginal, getEmptyUserDetail()]);
	}

	const save = () => {
		const s = userList.filter(item => (item.userModified) && item);

		if(s.length === 0) {
			toast.warn('Não existem registro para salvar');

			return;
		}

		setIsRunning(true);

		api.post('/api/postUserList', { data: userList })
		.then(res => res.data)
		.then(res =>{
			setIsRunning(false);

			if(res.code === 999) {
				toast.error(res.message);

				navigate('/login');
			} else if(res.code === 998) {
				toast.error(res.message);
			} else if(res.code !== 0) {
				toast.error(res.message);
			} else {
				fillUserList();
			}
		})
		.catch(err => {
			setIsRunning(false);
			toast.error(err);
		});
	}

	type UserCitiesReturn = {
		code: number;
		message: string;
		data: UserCidadeDetail[];
	}

	const getUserCities = async(user: string) => {
		return new Promise<UserCitiesReturn>((resolve, reject) => {

			api.get('/api/getUserCities', {
				params: {
					user: user,
				}
			})
			.then(res => res.data)
			.then(res => {
				resolve({
					code: 		res.code,
					message: 	res.message,
					data:		res.list,
				});
			})
			.catch(err => {
				resolve({
					code: 		9,
					message: 	err,
					data:		[],
				});
			});
		});
	}

	type UserPermissionsReturn = {
		code: number;
		message: string;
		data: UserPermissionDetail[];
	}

	const getUserPermissions = async(user: string) => {
		return new Promise<UserPermissionsReturn>((resolve, reject) => {

			api.get('/api/getUserPermissions', {
				params: {
					user: user,
				}
			})
			.then(res => res.data)
			.then(res => {
				resolve({
					code: 		res.code,
					message: 	res.message,
					data:		res.list,
				});
			})
			.catch(err => {
				resolve({
					code: 		9,
					message: 	err,
					data:		[],
				});
			});
		});
	}

	const handleChangePassword = (login: string) => {
		if(login === 'admin') {
			toast.warn('A senha do administrador não pode ser reiniciada pela interface web');

			return;
		}

		if(login.startsWith('wall.')) {
			if(!window.confirm(`A senha do usuário ${login} será reiniciada para o padrão wall. Confirma?`)) {
				return;
			}
		} else {
			if(!window.confirm(`A senha do usuário ${login} será reiniciada para '123mudar'. Confirma?`)) {
				return;
			}
		}

		setIsRunning(true);
		api.post('/api/restartPassword', {
			userLogin: login,
		})
		.then(res => res.data)
		.then(res => {
			if(res.code !== 0) {
				toast.error(res.message);
			} else {
				toast.info(`Senha do usuário '${login}' reiniciada`);
			}
		})
		.catch(err => {
			toast.error(err);
		})
		.finally(() => {
			setIsRunning(false);
		})
	}

	const handleShowUserDetail = async (login: string) => {
		setIsRunning(true);
		setCurrentUser(login);

		try {
			const cities = await getUserCities(login);

			if(cities.code !== 0) {
				throw new Error(cities.message);
			}

			setUserCidadeList(cities.data);

			const permissions = await getUserPermissions(login);



			if(permissions.code !== 0) {
				throw new Error(permissions.message);
			}

			setUserPermissionList(permissions.data);
		} catch(err: any) {
			toast.error(err);
		}

		setIsRunning(false);
	}

	const getTypeDesc = (type: number) => {
		switch(+type) {
		case 0:
			return "função";
		case 1:
			return "menu";
		default:
			return "invalida";
		}
	}

	const handleRemoveCity = (user: string, cidadeCodigo: number) => {
		setIsRunning(true);

		api.delete('/api/removeUserCity', {
			params: {
				user: user,
				city: cidadeCodigo
			},
		})
		.then(res => res.data)
		.then(res => {
			if(res.code !== 0) {
				toast.error(res.message);
			} else {
				handleShowUserDetail(user);
			}
		})
		.catch(err => {
			toast.error(err);
		})
		.finally(() => {
			setIsRunning(false);
		});
	}

	const handleNewUserCity = (user: string) => {
		if(currentUser === '') {
			return;
		}

		setNewUserCityIsOpen(true);
	}

	const handleNewUserCitySelected = (cidadeCodigo: number) => {
		setIsRunning(true);

		api.post('/api/addUserCity', {
			params: {
				user: currentUser,
				city: cidadeCodigo
			},
		})
		.then(res => res.data)
		.then(res => {
			if(res.code !== 0) {
				toast.error(res.message);
			} else {
				handleShowUserDetail(currentUser);
				setNewUserCityIsOpen(false);
			}
		})
		.catch(err => {
			toast.error(err);
		})
		.finally(() => {
			setIsRunning(false);
		});
	}

	const userHasPermission = (resourceID: number) => {
		let ret = false;

		userPermissionList.every(item => {
			if(resourceID === item.resourceID) {
				ret = true;

				return false
			} else {
				return true;
			}
		});

		return ret;
	}

	const handleChangePermission = (resourceID: number) => {
		let found = false;
		userPermissionList.every((item, index) => {
			if(resourceID === item.resourceID) {
				const newArray = [...userPermissionList.slice(0, index), ...userPermissionList.slice(index + 1)];
				setUserPermissionList(newArray);
				found = true;

				return false
			} else {
				return true;
			}
		});

		if(!found) {
			const x: UserPermissionDetail = {resourceID: resourceID};
			setUserPermissionList([...userPermissionList, x]);
		}
	}

	const handleSavePermissions = () => {
		setIsRunning(true);

		api.post('/api/saveUserPermissions', {
			user: currentUser,
			data: userPermissionList
		})
		.then(res => res.data)
		.then(res => {
			if(res.code === 999) {
				toast.error(res.message);

				navigate('/login');
			} else if(res.code === 998) {
				toast.error(res.message);
			} else if(res.code !== 0) {
				toast.error(res.message);
			} else {
				console.log('ok')
				handleShowUserDetail(currentUser);
			}
		})
		.catch(err => {
			toast.error(err);
		})
		.finally(() => {
			setIsRunning(false);
		})
	}

	return (
		<Container isOpen={props.isOpen}>
			<div className="header-div">
				<label>Cadastro de Usuários</label>
				<button onClick={() => props.onClose()} title="Fecha a janela"><img src={close} alt="Fecha a janela" /></button>
			</div>
			<div className="main-div">
				<ContainerList>
					<table>
						<thead>
							<tr>
								<th>Login</th>
								<th>Nome</th>
								<th>Ativo</th>
								<th>Último Acesso</th>
								<th>Data Criação</th>
								<th>Ações</th>
							</tr>
						</thead>
						<tbody>
							{
								userList.map((item, index) => {
									return (
										<tr key={index} className={item.userModified?'tr-line-modified':undefined}>
											<td><input type="text" size={10} value={item.userLogin} onChange={(e) => {setUserData('login', index, e.target.value)}} /></td>
											<td><input type="text" value={item.userName} onChange={(e) => {setUserData('name', index, e.target.value)}} /></td>
											<td><input type="checkbox" checked={(+item.userEnabled===0)?false:true} onChange={(e) => {setUserData('enabled', index, e.target.value)}}/></td>
											<td>{item.userLastLogin}</td>
											<td>{item.userCreation}</td>
											<td>
												<img className="action-image" src={changePassword} alt="Reiniciar Senha" title='Reiniciar Senha' onClick={() => handleChangePassword(item.userLogin)}/>
												<img className="action-image" src={arrow} alt="Mostra Permissões" title='Mostra Permissões' onClick={() => handleShowUserDetail(item.userLogin)}/>
											</td>
										</tr>
									)
								})
							}
						</tbody>
						<tfoot>
							<tr>
								<th colSpan={6}>
									<button onClick={newUser}>Novo</button>
									<button onClick={save}>Salvar</button>
								</th>
							</tr>
						</tfoot>
					</table>
				</ContainerList>
				<ContainerDetail>
					<table>
						<thead>
							<tr><th colSpan={6}>Permissões de {currentUser}</th></tr>
							<tr>
								<th>Ativo</th>
								<th>Nome</th>
								<th>Descrição</th>
								<th>Tipo</th>
							</tr>
						</thead>
						<tbody>
							{
								resourceList.map((item, index) => {
									const a = new Array(item.resourceDepth===1?0:item.resourceDepth*2).fill(' ');
									return (
										<tr key={index}>
											<td><input type="checkbox" checked={userHasPermission(item.resourceID)} onChange={() => handleChangePermission(item.resourceID)}/></td>
											<td>
												{
													a.map((i, index) => <label key={index}>&nbsp;</label>)
												}
												{item.resourceName}
											</td>
											<td>{item.resourceDescription}</td>
											<td>{getTypeDesc(item.resourceType)}</td>
										</tr>
									)
								})
							}
						</tbody>
						<tfoot>
							<tr>
								<th colSpan={6}>
									<button onClick={handleSavePermissions}>Salvar</button>
								</th>
							</tr>
						</tfoot>
					</table>
				</ContainerDetail>
				<ContainerCidade>
					<table>
						<thead>
							<tr><th colSpan={4}>Cidades de {currentUser}</th></tr>
							<tr>
								<th>Código</th>
								<th>Nome</th>
								<th>Estado</th>
								<th>Ação</th>
							</tr>
						</thead>
						<tbody>
							{
								userCidadeList && userCidadeList.map((item, index) => {
									return (
										<tr key={index}>
											<td>{item.cidadeCodigo}</td>
											<td>{item.cidadeNome}</td>
											<td>{item.cidadeEstado}</td>
											<td><img src={remove} className="remove-img" alt="Remover Cidade" title="Remover Cidade" width={16} onClick={() => handleRemoveCity(currentUser, item.cidadeCodigo)}/></td>
										</tr>
									)
								})
							}
						</tbody>
						<tfoot>
							<tr>
								<th colSpan={4}>
									<button onClick={() => handleNewUserCity(currentUser)}>Adicionar</button>
								</th>
							</tr>
						</tfoot>
					</table>
				</ContainerCidade>
			</div>
			<ModalNewUserCity
				isOpen={newUserCityIsOpen}
				onClose={() => setNewUserCityIsOpen(false)}
				onItemSelected={(cidadeCodigo) => handleNewUserCitySelected(cidadeCodigo)}
			/>
			<WaitBox show={ isRunning } />
		</Container>
	);
}