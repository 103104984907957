import { Container } from "./styles";
import close from '../../imgs/close.png';
import { useEffect, useState } from "react";
import { api } from "../../Services/api";
import { WaitBox } from '../WaitBox';

interface StatusProps {
	isOpen: boolean;
	onError: (res: number) => void;
	onClose: () => void;
}
interface Queue {
	queueName: string;
	queueLength: number;
}

interface StatusA {
	queue: Queue[];
}

export const Status = (props: StatusProps) => {
	const [ status, setStatus ] 						= useState<StatusA>({} as StatusA);
	const [ isRunning, setIsRunning ] 					= useState(false);

	useEffect(() => {
		getStatus();
	},[props.isOpen]);

	const getStatus = async() => {
		setIsRunning(true);

		api.get('/api/getStatus')
		.then(res => res.data)
		.then(res => {
			if(res.code !== 0) {
				alert(res.message);
			} else {
				setStatus(res.status);
			}
		})
		.catch(err => alert(err))
		.finally(() => setIsRunning(false))
	}

	return (
		<Container isOpen={props.isOpen}>
			<div className="header-div">
				<label>Estado do Sistema</label>
				<button onClick={() => props.onClose()} title="Fecha a janela"><img src={close} alt="Fecha a janela" /></button>
			</div>
			<table>
				<thead>
					<tr><th colSpan={2}>Filas</th></tr>
				</thead>
				<tbody>
					{
						status.queue?.map((item, index) => {
							return (
								<tr key={item.queueName}>
									<td>{item.queueName}</td>
									<td>{item.queueLength}</td>
								</tr>
							)

						})
					}
				</tbody>
				<tfoot>
					<tr>
						<td colSpan={2}>
							<button onClick={()=>{getStatus()}}>Atualizar</button>
						</td>
					</tr>
				</tfoot>
			</table>
			<WaitBox show={ isRunning } />
		</Container>
	);
}