import { memo, CSSProperties } from "react";
import FakeImg from '../../imgs/fakeImg.png';

interface Props {
	style: CSSProperties,
	data: string|null,
}

const ImageView = (props: Props) => {
	return (
		(!props.data)?
			<img style={ props.style } src={ FakeImg } alt=""></img>:
			<img style={ props.style } src={`data:image/jpeg;base64,${props.data}`} alt=""></img>
	);
}

export const ImageViewMemo = memo(ImageView);