import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchPlate } from '../SearchPlate';
import { Menu } from '../Menu';
import auth from '../../Services/auth';
import { CadastroMaquina } from '../CadastroMaquina';
import { CadastroUsuario } from '../CadastroUsuario';
import { Container } from './styles';
import { Status } from '../Status';
import { MainMemo } from '../Main';
import { ConfigLocal } from '../ConfigLocal';

export const Dashboard = () => {
	const [ showMenu, 	setShowMenu ] 				= useState(false);
	const [ item, 		setItem] 					= useState('');
	const navigate 									= useNavigate();


	console.log('dashboard running');

	const toggleShowMenu = () => {
		setShowMenu(!showMenu);
	}

	const handleMenuItemClick = (item: string) => {
		setShowMenu(false);

		if(item === 'sair') {
			auth.logout(() => {
				navigate('/login');
			});
		} else {
			setItem(item);
		}
	}

	const handleError = (res: number) => {
		if(res === 999) {
			navigate('/login');
		} else {
			setItem('');
		}
	}

	return (
		<Container>
			<div className="header">
				<div className="headerLeft">
					<Menu show={ showMenu } onShowClick={toggleShowMenu} onMenuClick={(item) => handleMenuItemClick(item)}/>
					<label>Central Detecta</label>
				</div>
			</div>
			{(item === 'pesquisa_placa') && <SearchPlate isOpen={item === 'pesquisa_placa'} onError={res => handleError(res)} onClose={() => setItem('')}/>}
			{(item === 'cadastro_maquina') && <CadastroMaquina isOpen={item === 'cadastro_maquina'} onError={res => handleError(res)} onClose={() => setItem('')}/>}
			{(item === 'cadastro_usuario') && <CadastroUsuario isOpen={item === 'cadastro_usuario'} onError={res => handleError(res)} onClose={() => setItem('')}/>}
			{(item === 'cadastro_status') && <Status isOpen={item === 'cadastro_status'} onError={res => handleError(res)} onClose={() => setItem('')}/>}
			{(item === 'config_local') && <ConfigLocal isOpen={item === 'config_local'} onError={res => handleError(res)} onClose={() => setItem('')}/>}
			<MainMemo />
		</Container>
	)
}