import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	justify-content: center;
	align-items: center;

	.title-box {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		align-items: center;
	};
	.title {
		font-size: 36px;
		font-weight: bold;
		width: 100%;
		text-align: center;
		border-bottom: 2px solid gray;
		margin-bottom: 5px;
	};
	.input-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	};
	input {
		border: 0;
		border-radius: 10px;
		padding: 8px;
		width: 300px;
		margin-top: 10px;
		box-shadow: 0px 0px 15px 1px #0000ff;
		background: black;
		color: white;
		font-size: 18;
	};
	.button-box {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: right;
		margin-top: 20px;
	};
	button {
		border: 0;
		border-radius: 10px;
		padding: 8px;
		font-size: 18px;
		font-weight: bold;
		box-shadow: 0px 0px 15px 1px #0000ff;
		cursor: pointer;
		color: blue;
		margin-left: 10px;
	};
	.downloading {
		margin-right: 10px;
		justify-content: center;
	};
`;