const resolveHostAddr = () => {
	//return 'https://monitoratransito.com.br';
	// 'http://127.0.0.1:9999',

	let siteAddr = window.location.origin;

	const last	= siteAddr.lastIndexOf(':');
	const first	= siteAddr.indexOf(':');

	if(first !== last) {
		siteAddr = siteAddr.slice(0, last);
	}

	return siteAddr;
}

const resolveHostAddrWS = () => {
	return 'https://monitoratransito.com.br';
	// 'http://127.0.0.1:9999',

	let siteAddr = window.location.origin;

	const last	= siteAddr.lastIndexOf(':');
	const first	= siteAddr.indexOf(':');

	if(first !== last) {
		siteAddr = siteAddr.slice(0, last);
	}

	return siteAddr;
}

//const wsPort	= process.env.NODE_ENV==='development'?8998:9998;
const wsPort	= 9998;
const apiPort	= 9999;

export const Config = {
	baseAddr: `${resolveHostAddr()}:${apiPort}`,
	//baseAddr: 'http://127.0.0.1:9999',
	//baseAddr: `https://monitoratransito.com.br:${apiPort}`,
	wsAddr: `${resolveHostAddrWS()
			.replace('http://', 'ws://')
			.replace('https://', 'wss://')}:${wsPort}`,
	//wsAddr: 'ws://127.0.0.1:9998',
}
