import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: auto;
	margin: 0px;
	z-index: 99999;

	.menu {
		position: absolute;
		box-shadow: 0px 0px 15px 1px #0000ff;
		border: 1px solid gray;
		border-radius: 5px;
		top: 50px;
		left: 20px;
		width: 200px;
		height: 300px;
		margin: 0 auto;
		background: black;

		.menuItem {
			display: flex;
			align-items: center;
			padding-left: 10px;
			cursor: pointer;
		}
	}

	.title {
		display: flex;
		background: red;
		justify-content: center;
		margin: 0 auto;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	.button {
		display: flex;
		margin-right: 10px;
		cursor: pointer;
	}

	.header {
		display: flex;
		align-items: center;
	}

	.headerText {
		font-size: 36px;
		font-weight: bold;
	}
`;