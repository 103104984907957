import Modal from 'styled-react-modal'

export const Container = Modal.styled`
	max-height: 85%;
	max-width: 85%;

	flex-direction: column;
	position: relative;
  	display: flex;
  	align-items: flex-start;
  	background-color: #505050;
	box-shadow: 0px 0px 15px 1px #0000ff;
	border-radius: 15px;
	padding: 10px 10px 10px 10px;
	min-width: 700px;

	.header-div {
		margin-top: 1px;
		margin-bottom: 15px;
		display: flex;
		width: 100%;
		justify-content: space-between;

		label {
			font-size: 36px;
		}

		button {
			border: 0;
			border-radius: 10px;
			width: 30px;
			height: 30px;
			cursor: pointer;

			img {
				width: 18px;
				height: 18px;
			}
		}
	}

	table {
		overflow: auto;
		width: 100%;
		border: 1px solid gray;
		border-radius: 5px;
		background: #404040;
		border-collapse: collapse;
		display: block;
		overflow: auto;
		height: 100%;
		padding: 10px;

		th {
			padding-left: 3px;
			padding-right: 3px;
			border: 1px solid gray;
			border-collapse: collapse;
		}

		tbody {
			td {
				border: 1px solid gray;
				border-collapse: collapse;
				padding: 2px 5px 2px 5px;
				white-space: nowrap;
				justify-content: right;
			}
		}

		tfoot {
			td {
				justify-content: right;
				text-align: right;
				margin-top: 10px;
				padding: 15px 5px 5px 5px;
				background: #404040;
				border-radius: 5px;
			}
		}

		button {
			border: 0;
			border-radius: 10px;
			padding: 8px;
			width: 90px;
			font-size: 16px;
			font-weight: bold;
			cursor: pointer;
			color: blue;
			margin-left: 10px;
		}
	}

`;