import styled from "styled-components";
import Modal from 'styled-react-modal'

export const ModalContainer = Modal.styled`
	position: relative;
	width: 500px;
  	display: flex;
  	align-items: flex-start;
  	justify-content: center;
  	background-color: #202020;
	box-shadow: 0px 0px 15px 1px #0000ff;
	border-radius: 15px;
`;

export const Container = styled.div`
	margin-top: 66px;

	label {
		position: absolute;
		top: 10px;
		color: #fff;
		font-weight: bold;
		font-size: 28px;
	}

	table {
		width: 100%;

		td {
			width: 100%;

			input {
				width: 400px;
				height: 30px;
				margin-bottom: 10px;
				box-shadow: 0px 0px 15px 1px #0000ff;
				border-radius: 10px;
				font-size: 20px;
				color: #fff;
				background: #505050;
				border: 1px solid blue;
				padding: 10px;
			}
		}
	}

	button {
		border: 0;
		border-radius: 10px;
		padding: 8px;
		font-size: 18px;
		font-weight: bold;
		box-shadow: 0px 0px 15px 1px #0000ff;
		cursor: pointer;
		color: blue;
		margin-left: 10px;
		margin-top: 15px;
		margin-bottom: 10px;
	}

	.td-button {
		text-align: right;
	}
`;

export const CloseButton = styled.button`
	position: absolute;
	top: 0;
	right: 0;
	margin-top: 5px;
	margin-right: 5px;
	width: 30px;
	height: 30px;

	img {
		width: 100%;
		height: 100%;
	}
`;