import styled from "styled-components";
import Modal from 'styled-react-modal'

export const Container = Modal.styled`
	flex-direction: column;
	position: relative;
  	display: flex;
  	align-items: flex-start;
  	background-color: #505050;
	box-shadow: 0px 0px 15px 1px #0000ff;
	border-radius: 15px;
	padding: 10px 30px 10px 30px;

	hr {
		width: 100%;
	}

	table {
		margin-top: 10px;
		tfoot {
			td {
				justify-content: right;
				text-align: right;
				margin-top: 10px;
				padding: 15px 5px 5px 5px;
				border-radius: 5px;
			}
		}
	}

	button {
		border: 0;
		border-radius: 10px;
		padding: 8px;
		width: 90px;
		font-size: 16px;
		font-weight: bold;
		cursor: pointer;
		color: blue;
		margin-left: 10px;
	}
`;
