import { useEffect, useRef, useState } from 'react';
import { Container } from './styles';
import DownArrow from '../../imgs/downarrow.png';
import UpArrow from '../../imgs/uparrow.png';
import close from '../../imgs/close.png';

interface DownloadProps {
    isOpen:     boolean;
    onClose:    () => void;
    data:       string|null;
}

export const Download = (props: DownloadProps) => {
    const canvasDownloadOri         = useRef<HTMLCanvasElement>(null);
    const canvasDownload            = useRef<HTMLCanvasElement>(null);
    const memoryImg                 = useRef<HTMLImageElement>(null);
    const [ text, setText ]         = useState('');
    const [ drawType, setDrawType ] = useState('TJ');
    const [ downloadLink, setDownloadLink ] = useState('');

    const updateDownloadImage = () => {
        updateCanvas();
    }

    const decreaseDownloadFontSize = () => {

    }

    const increaseDownloadFontSize = () => {

    }

    const downloadFinalImage = (a: any) => {
        const canvas    = canvasDownload.current;
        
        if(canvas) {
            setDownloadLink(canvas.toDataURL('/image/jpeg'));
        }
    }

    const updateText = (value: string) => {
        setText(value); 
        updateDownloadImage();
    }

    useEffect(() => {
        updateDownloadImage();
    }, [drawType, text]);

    const updateDrawType = (type: string) => {
        setDrawType(type);
    }

    const updateCanvas = () => {
        const canvas    = canvasDownload.current;
        const ctx       = canvas?.getContext('2d');
        let   textPos   = 50;

        if(canvas && ctx) {
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            ctx.drawImage(memoryImg.current as CanvasImageSource, 0, 0);

            ctx.font                    = '26px Arial';
            ctx.globalAlpha             = 0.5;
            ctx.imageSmoothingEnabled   = true;

            ctx.fillStyle = '#000000';
            
            const textMetrics = ctx.measureText(text);

            if(drawType === 'TJ') {
                ctx.globalAlpha = 1.0;
                ctx.fillStyle   = '#ffffff';
                const height = (14 + textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent);
                ctx.fillRect(0, canvas.height - height, canvas.width, canvas.height);
            }
        
            ctx.fillStyle = '#000000';
            ctx.fillText(text, canvas.width - textMetrics.width - 10, canvas.height - 8);

            /*if(useLogos) {
                ctx.drawImage(logo1, 50, 0, 48, 48);
                ctx.drawImage(logo2, 100, 0, 48, 48);
                ctx.drawImage(logo3, 150, 0, 48, 48);
                textPos = 210;
            }*/

        }
    }

    //const Canvas = props => <canvas {...props} />
    return (
        <Container isOpen={props.isOpen}>
            <div className="header-div">
				<label>Download de Imagem</label>
				<button onClick={() => props.onClose()} title="Fecha a janela"><img src={close} alt="Fecha a janela" /></button>
			</div>
            <table>
                <tr>
                    <td>
                    </td>
                    <td>
                        Descrição: <input type="text" id="downloadDescricao" width="100%" value={text} onChange={(e) => updateText(e.target.value)} />
                        <input type="radio" id="downloadOption" value="MD" onChange={() => updateDrawType('MD')} checked={drawType==='MD'} /><label>Marca d'água</label>
                        <input type="radio" id="downloadOption" value="TJ" onChange={() => updateDrawType('TJ')} checked={drawType==='TJ'} /><label>Tarja</label>
                        {/*<button onClick={() => decreaseDownloadFontSize()}><img width="16px" height="16px" src={DownArrow} /></button>
                        <button onClick={() => increaseDownloadFontSize()}><img width="16px" height="16px" src={UpArrow} /></button>*/}
                        <br /><br />
                        <img ref={memoryImg} style={{ display: 'none' }} onLoad={updateCanvas} src={`data:image/jpeg;base64,${props.data}`} />
                        <canvas ref={canvasDownload} style={{ background: 'white' }} width="704px" height="480px"></canvas><br />
                        <a id="downloadLink" download="image.jpg" href={downloadLink} onClick={() => downloadFinalImage(this)}>Download Imagem</a>
                    </td>
                </tr>
            </table>
        </Container>
    )
}