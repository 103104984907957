import useWebSocket, { ReadyState } from 'react-use-websocket';
import auth from '../Services/auth';
import { Config } from '../config';
import { connect } from 'http2';
import { useState } from 'react';

type Callback 		= (message: string) => void;
type CallbackStatus	= (status: boolean) => void;

export const WS = (callback: Callback, status: CallbackStatus) => {
	const [ connectWS, setConnectWS ] 	= useState(false);

	const { sendMessage, lastMessage, readyState, } = useWebSocket(Config.wsAddr, {
		onOpen: 			() => onWSOpen(),
		onClose: 			() => onWSClose(),
		onError: 			(e: Event) => onWSError(e),
		onMessage: 			(event) => onWSMessage(event),
		shouldReconnect: 	(closeEvent) => true,
		reconnectAttempts: 99999999999,
		queryParams: {
			'authorization': auth.getToken(),
		},

	}, connectWS);

	const onWSOpen = () => {
		status(true);
		console.log('ws is now opened');
		sendMessage('Ping');
	}

	const onWSError = (e: Event) => {
		console.log(e);
	}

	const onWSClose = () => {
		status(false);
	}

	const onWSMessage = (data: WebSocketEventMap['message']) => {
		callback(data.data);
	}

	const connect = () => {
		console.log('ws connect');

		setConnectWS(true);
	}

	const send = (message:  string) => {
		sendMessage(message);
	}

	return {
		connect: connect,
		send: send,
	}
}
