import { memo, useEffect, useState } from 'react';
import { api } from '../../Services/api';
import { WaitBox } from '../WaitBox';
import { WS } from '../../Services/ws';
import { Container, Wall, WallContainer } from './styles';
import { MdOutlineRefresh } from 'react-icons/md';
import RedBall from '../../imgs/redBall.png';
import GreenBall from '../../imgs/greenBall.png';
import { wallAPI } from '../../Services/wallAPI';
import auth from '../../Services/auth';
import { ImageContainerMemo } from '../ImageContainer';

interface Maquina {
	maquinaSerial: 		string;
	maquinaFaixas:		string;
	maquinaCodCidade: 	number;
	maquinaCidade:		string;
}

interface GenericWSMessage {
	message: string;
	payload: any;
}

interface Point {
	s: number;
	y: number;
}

interface Plate {
	plate: string;
	confidence: number[];
	topLeft: Point;
	topRight: Point;
	bottomRight: Point;
	bottomLeft: Point;
}

interface Passagem {
	partnerID: number,
	maquina: string;
	faixa: number;
	datetime: string;
	epoch: number;
	latitude: string;
	longitude: string;
	sequence: string;
	cidade: string;
	plate: Plate;
}

const Main = () => {
	const [ maquinaList,		setMaquinaList ]		= useState<Maquina[]>([] as Maquina[]);
	const [ isRunning, 			setIsRunning ] 			= useState(false);
	const [ listenMaquina, 		setListenMaquina ]		= useState<string[]>(['', '', '', '']);
	const [ listenMaquinaComp, 	setListenMaquinaComp ]	= useState<string[]>(['', '', '', '']);
	const [ listenMaquinaImg, 	setListenMaquinaImg ]	= useState<string[]>(['', '', '', '']);
	const [ addrWall1, 			setAddrWall1 ]			= useState('');
	const [ addrWall2, 			setAddrWall2 ]			= useState('');
	const [ passagemList, 		setPassagemList ]		= useState<Passagem[]>([] as Passagem[]);
	const [ wsStatus, 			setWSStatus ]			= useState(false);
	const ws = WS(payload => onWSMessage(payload), status => onWSStatusChange(status));

	useEffect(() => {
		getMaquinaList();

		const data = localStorage.getItem('@detecta:listenMaquina');

		if(data) {
			setListenMaquina(JSON.parse(data));
		}

		const addrWallStorage1	= localStorage.getItem('@detecta:addrWall1');

		if(addrWallStorage1) {
			setAddrWall1(addrWallStorage1);
		}

		const addrWallStorage2	= localStorage.getItem('@detecta:addrWall2');

		if(addrWallStorage2) {
			setAddrWall2(addrWallStorage2);
		}

		const timer = setInterval(() => {
			ws.send('Ping');
			apiPing();
		}, 10 * 1000); // 1 minute

		ws.connect();

		return() => {
			clearInterval(timer);
		}
	}, []);

	const onWSStatusChange = (status: boolean) => {
		setWSStatus(status);
	}

	const onWSMessage = (payload: string) => {
		const json = JSON.parse(payload) as GenericWSMessage;

		switch(json.message) {
		case 'passagem':
			const passagem 			= json.payload as Passagem;
			const indexes: number[]	= [];
			const text 				= `${passagem.partnerID} - ${convertDate(passagem.datetime)} - ${passagem.plate.plate}`;;

			addPassagem(passagem);

			listenMaquina.forEach((maquina, index) => {
				const maquinaStr 	= maquina.toString();
				let   foundCity		= false;

				if(maquinaStr.startsWith('CITY:')) {
					const tokens = maquinaStr.split(':');

					if(+passagem.cidade===+(tokens[1])) {
						foundCity = true;
					}
				}

				if(maquina === passagem.maquina || foundCity) {
					indexes.push(index);
				}
			});

			if(indexes.length > 0) {
				getImage(passagem.partnerID, indexes, text);
			}

			break;
		}
	}

	const convertDate = (date: string) => {
		return `${date.slice(8, 10)}/${date.slice(5, 7)}/${date.slice(0, 4)} ${date.slice(11)}`;
	}

	const apiPing = () => {
		api.get('/api/ping')
		.then(res => res.data)
		.then( res => {
			if(res.code !== 0) {
				throw new Error(res.message);
			}
		})
		.catch(err => console.log('ping', err))
	}

	const getMaquinaList = () => {
		setIsRunning(true);
		api.get('/api/getMaquinaResume')
		.then(res => res.data)
		.then( res => {
			if(res.code !== 0) {
				throw new Error(res.message);
			}

			setMaquinaList(res.list);
		})
		.catch(err => alert(err))
		.finally(() => setIsRunning(false));
	}

	const getImage = async (partnerID: number, indexes: number[], text: string) => {
		api.get('/api/getImage', {
			params: {
				partnerID: partnerID,
			}
		})
		.then(res => res.data)
		.then(res => {
			const x = listenMaquinaImg;
			if(res.code === 0) {
				indexes.forEach(index => x[index] = `data:image/jpg;base64,${res.image}`);
			} else {
				indexes.forEach(index => x[index] = '');
			}

			const comp = listenMaquinaComp;

			indexes.forEach(index => comp[index] = text);
			setListenMaquinaImg(x);
			setListenMaquinaComp(comp);
		})
		.catch(err => {
			console.log(err);
			const x = listenMaquinaImg;
			indexes.forEach(index => x[index] = '');
			setListenMaquinaImg(x);
		})
	}

	const dragStart = (e: any, maquinaId: string) => {
		e.dataTransfer.setData('id', maquinaId);
	}

	const drop = (e: any, which: number) => {
		e.stopPropagation();
		const maquina = e.dataTransfer.getData('id');

		const x 	= listenMaquina.map((i, index) => index!==which?i:maquina);
		const comp 	= listenMaquinaComp;

		comp[which] = '';

		setListenMaquina(x);
		setListenMaquinaComp(comp);

		localStorage.setItem('@detecta:listenMaquina', JSON.stringify(x));
	}

	const dropWall = (e: any, panel: number, which: number) => {
		const addrWall = panel===0?addrWall1:addrWall2;

		if(addrWall && addrWall !== '' && addrWall.endsWith(':50000')) {
			wallAPI.post(`${addrWall}/setPanel`, {
				pos: which + 1,
				maquina: e.dataTransfer.getData('id')
			})
			.catch(err => {
				console.log(err);
			});
		}
	}

	const addPassagem = (passagem: Passagem) => {
		setPassagemList(oldState => {
			const len = oldState.length >= 16?16:oldState.length;

			return [passagem, ...oldState.slice(0, len)];
		});
	}

	let cidade = '';

	return (
		<Container>
			<div className='main-div'>
				<div className='main-image-div'>
					<ImageContainerMemo
						which={0}
						onDrop={(e: any, which: number) => drop(e, which)}
						listenMaquina={listenMaquina[0]}
						listenMaquinaImg={listenMaquinaImg[0]}
						listenMaquinaComp={listenMaquinaComp[0]}
					/>
					<ImageContainerMemo
						which={1}
						onDrop={(e: any, which: number) => drop(e, which)}
						listenMaquina={listenMaquina[1]}
						listenMaquinaImg={listenMaquinaImg[1]}
						listenMaquinaComp={listenMaquinaComp[1]}
					/>
					<ImageContainerMemo
						which={2}
						onDrop={(e: any, which: number) => drop(e, which)}
						listenMaquina={listenMaquina[2]}
						listenMaquinaImg={listenMaquinaImg[2]}
						listenMaquinaComp={listenMaquinaComp[2]}
					/>
					<ImageContainerMemo
						which={3}
						onDrop={(e: any, which: number) => drop(e, which)}
						listenMaquina={listenMaquina[3]}
						listenMaquinaImg={listenMaquinaImg[3]}
						listenMaquinaComp={listenMaquinaComp[3]}
					/>
					<div className='walls'>
						<Wall show={addrWall1.endsWith(':50000')}>
							<div className="wall-main">
								<WallContainer onDrop={e => dropWall(e, 0, 0)} onDragOver={e => e.preventDefault()}><span>1</span></WallContainer>
								<WallContainer onDrop={e => dropWall(e, 0, 1)} onDragOver={e => e.preventDefault()}><span>2</span></WallContainer>
								<WallContainer onDrop={e => dropWall(e, 0, 2)} onDragOver={e => e.preventDefault()}><span>3</span></WallContainer>
								<WallContainer onDrop={e => dropWall(e, 0, 3)} onDragOver={e => e.preventDefault()}><span>4</span></WallContainer>
							</div>
							<footer>
								<span>Wall 1</span>
							</footer>
						</Wall>
						<Wall show={addrWall2.endsWith(':50000')}>
							<div className="wall-main">
								<WallContainer onDrop={e => dropWall(e, 1, 0)} onDragOver={e => e.preventDefault()}><span>1</span></WallContainer>
								<WallContainer onDrop={e => dropWall(e, 1, 1)} onDragOver={e => e.preventDefault()}><span>2</span></WallContainer>
								<WallContainer onDrop={e => dropWall(e, 1, 2)} onDragOver={e => e.preventDefault()}><span>3</span></WallContainer>
								<WallContainer onDrop={e => dropWall(e, 1, 3)} onDragOver={e => e.preventDefault()}><span>4</span></WallContainer>
							</div>
							<footer>
								<span>Wall 2</span>
							</footer>
						</Wall>
					</div>
				</div>
				<div className="main-right">
				 	<div className="top-label"><b>Usuário:</b> {auth.getUser()}</div>
					<div className="maquina-div">
						<header>
							<button onClick={() => getMaquinaList()}>
								<MdOutlineRefresh title='Atualiza Lista' size={32} style={{ color: 'white' }}/>
							</button>
							<span>Máquinas</span>
							<div><img alt="" src={wsStatus?GreenBall:RedBall} /></div>
						</header>
						<table>
							<tbody>
								{
									maquinaList.map((maquina, index) => {
										if(maquina.maquinaCidade !== cidade) {
											cidade = maquina.maquinaCidade;

											return (
												<>
												<tr key={`${maquina.maquinaCidade}-${maquina.maquinaSerial}`}>
													<td className='td-bold draggable' colSpan={2} draggable onDragStart={e => dragStart(e, `CITY:${maquina.maquinaCodCidade}:${maquina.maquinaCidade}`)}>
																	{maquina.maquinaCidade}
													</td>
												</tr>
												<tr key={`${index}`}>
													<td draggable className="draggable" onDragStart={e => dragStart(e, `${maquina.maquinaSerial}`)}>{maquina.maquinaSerial}</td>
													<td>{maquina.maquinaFaixas}</td>
												</tr>
												</>
											)
										} else {
											return (
												<tr key={`${index}`}>
													<td draggable className="draggable" onDragStart={e => dragStart(e, `${maquina.maquinaSerial}`)}>{maquina.maquinaSerial}</td>
													<td>{maquina.maquinaFaixas}</td>
												</tr>
											)
										}
									})
								}
							</tbody>
						</table>
					</div>
					<div className="passagens-div">
						<header><div></div><span>Passagens</span><div></div></header>
						<div className='passagens-lista'>
							<ul>
								{
									passagemList.map((passagem, index) => {
										return (
											<li key={index}>
												{`${passagem.partnerID} - ${passagem.plate.plate} - ${passagem.maquina}-${passagem.faixa} - ${passagem.cidade}`}
											</li>
										)
									})
								}
							</ul>
						</div>
					</div>
				</div>
			</div>
			<WaitBox show={ isRunning } />
		</Container>
	)
}

export const MainMemo = memo(Main);