import { useEffect, useState } from "react";
import { MdSave } from "react-icons/md";
import { Container } from "./styles";

interface ConfigLocalProps {
	isOpen: boolean;
	onError: (res: number) => void;
	onClose: () => void;
}

export const ConfigLocal = (props: ConfigLocalProps) => {
	const [ addrWall1, setAddrWall1 ] = useState('');
	const [ addrWall2, setAddrWall2 ] = useState('');

	useEffect(() => {
		const addrWallStorage1	= localStorage.getItem('@detecta:addrWall1');

		if(addrWallStorage1) {
			setAddrWall1(addrWallStorage1.replace('https://', '').replace(':50000', ''));
		}

		const addrWallStorage2	= localStorage.getItem('@detecta:addrWall2');

		if(addrWallStorage2) {
			setAddrWall2(addrWallStorage2.replace('https://', '').replace(':50000', ''));
		}
	},[props.isOpen]);

	const save = () => {
		if(addrWall1 !== '') {
			localStorage.setItem('@detecta:addrWall1', `https://${addrWall1}:50000`);
		} else {
			localStorage.setItem('@detecta:addrWall1', ``);
		}

		if(addrWall2 !== '') {
			localStorage.setItem('@detecta:addrWall2', `https://${addrWall2}:50000`);
		} else {
			localStorage.setItem('@detecta:addrWall2', ``);
		}

		alert('Atualize o browser para que as configurações tenham efeito');

		props.onClose();
	}

	return (
		<Container isOpen={props.isOpen}>
			<span>Configurações Locais</span>
			<hr />
			<table>
				<tbody>
					<tr>
						<td>Endereço Wall 1</td>
						<td><input type="text" placeholder="<ip>" value={addrWall1} onChange={(e) => setAddrWall1(e.target.value)} /></td>
					</tr>
					<tr>
						<td>Endereço Wall 2</td>
						<td><input type="text" placeholder="<ip>" value={addrWall2} onChange={(e) => setAddrWall2(e.target.value)} /></td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<td colSpan={2}>
							<button onClick={()=>{save()}}>Salvar</button>
						</td>
					</tr>
				</tfoot>
			</table>
		</Container>
	);
}
